#modalPopupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  visibility: hidden;
  overflow: auto;

  #modalPopupOverlayScrim {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: block;
  }

  #modalPopupOverlayInner {
    @include calc(width, '100% - 200px');
    margin: 50px auto;
    max-width: 695px;
    border-radius: 10px;
    background-color: #fff;
    @media only screen and (max-width: $breakpoint-small) {
      @include calc(width, '100%');
      max-width: unset;
      margin: 0 0 0;
      border-radius: 0;
    }

    .overlay-x {
      position: absolute;
      z-index: 20;
      right: 10px;
      top: 10px;
    }



    .inner2 {
      width: auto;
      padding: 30px 50px 20px;

      .top-txt {
        min-height: 200px;
      }

      h1, h2, h3, h4, h5, h6 {
        //text-transform: uppercase;
        //margin-bottom: 20px;
        //@include font-rubik-black();
        //font-size: 42px;
        text-align: left;
      }

      p, a {
        //font-size: 14px;
        //line-height: 1em;
      }

      p {
        margin-bottom: 10px;
      }


      .terms {
        margin: 20px 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .styled-checkbox {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          width: 20px;
          height: 20px;
          border: 1px solid #000;
          cursor: pointer;
          display: inline-block;
        }

        /* Checked state: add the black checkmark */
        .styled-checkbox:checked {
          background-color: #fff;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='black'%3E%3Cpath d='M9 19L2 12l1.4-1.4L9 16.2l12-12 1.4 1.4L9 19z'/%3E%3C/svg%3E");
          background-size: 12px 12px;
          background-position: center;
          background-repeat: no-repeat;
        }

        span {
          margin-left: 15px;
          @include font-roboto-bold();
          font-size: 16px;
        }
      } //terms
    }//inner 2

  }


}



.bottom-buttons {
  display: flex;
  width: 100%;
  &.popup {

  }
  &.mobileFixed {
    position: fixed;
    left:0;
    bottom: 0;
    z-index: 1000;
    display: none;
    @media only screen and (max-width: $breakpoint-small) {
      display: flex;
    }
  }

  .code {
    width: 50%;
    background-color: #E6E6E6;
    display: flex;
    align-items: center;
    justify-content: center;
    @include font-rubik-regular();
    font-size: 16px;
    letter-spacing: 0.1em;
    line-height: 1em;
    b {
      @include font-rubik-extrabold();
    }
    @media only screen and (max-width: $breakpoint-smaller) {
      font-size: 14px;
    }
    .code2 {
      text-align: center;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  .button-outer {
    width: 50%;
    .btn {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0;
      padding-left: 5px;
      padding-right: 5px;
    }
    &.button-outer {
      &.not-accepted {
        pointer-events: none;
        .btn.active {
          opacity: 0.3;
          cursor: no-drop;
        }
      }
      &.accepted {
        pointer-events: all;
        .btn {

        }
      }
      .btn {
        &.sold-out, &.not-on-sale {
          cursor: no-drop;
          background-color: #4A4A4A;
          color: #9E9E9E;
        }
        &.coming-soon {
          cursor: no-drop;
        }
      }

    }
  }
}
