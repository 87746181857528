.block-across-the-us {
  .grid {
    padding-bottom: 100px;
    display: grid;
    row-gap: 100px;
    column-gap: 40px;
    grid-template-columns: repeat(4, 1fr);
    @media (max-width: $breakpoint-medium) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: $breakpoint-small) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 30px;
      column-gap: 30px;
    }
    .poster {
      width: 100%;
      height: auto;
      aspect-ratio: 1;
      border-radius: 10px;
      display: block;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .copy {
      margin-top: 15px;
      h3 {
        margin:10px 0 3px;
        color: #fff;
        @include font-rubik-semibold();
        font-size:18px;
        text-transform: uppercase;
      }
      h4 {
        margin:3px 0;
        color: #fff;
        @include font-rubik-regular();
        font-size:16px;
        text-transform: uppercase;
        a {
          color: $color-red;
          @include transition-all();
          body.device-notmobile & {
            &:hover {
              color: $color-red-dark-hover;
            }
          }
        }
      }
      p {
        margin:4px 0;
        &.tdate {
          margin:10px 0;
        }
        color: #fff;
        @include font-rubik-regular();
        font-size:14px;
        text-transform: uppercase;
      }
    }

  }
}