.block-participating-shows {
  background-color: #1C1C1C;
  padding:50px 0 100px;

  h3 {
    @include font-roboto-regular();
    font-size: 24px;
    color: #fff;
    text-transform: uppercase;
  }
  .title-row {
    justify-content: space-between;
    display: flex;
    align-items: center;
    z-index: 100;
    margin:40px 66px 0;
    @media only screen and (max-width: $breakpoint-small) {
      margin:40px 0 0;
    }
    .title-row-l {
      @include font-rubik-regular();
      font-size: 24px;
      letter-spacing: 0.05em;
    }
    .title-row-r {
      justify-content: flex-end;
      display: inline-flex;
      align-items: center;
      min-width: 150px;
      .chip {
        @media only screen and (max-width: $breakpoint-small) {
          display: none;
        }
        background-color: #000;
        border-radius: 10px;
        padding:4px 10px;
        margin-right: 20px;
        .txt {
          letter-spacing: 0.1em;
          @include font-rubik-medium();
          text-transform: uppercase;
          font-size: 14px;
          color: #fff;
        }
      }
      .all {
        justify-content: flex-end;
        display: inline-flex;
        align-items: center;
        a {
          letter-spacing: 0.1em;
          @include font-rubik-extrabold();
          text-transform: uppercase;
          font-size: 16px;
          color: $color-red;
          @include transition-all();
          justify-content: flex-end;
          display: inline-flex;
          align-items: center;
          span {
            top:2px;
          }
          svg {
            margin-left: 10px;
            top:0;
            left:0;
            @include transition-all();
            path {
              fill: $color-red;
              @include transition-all();
            }
          }
          body.device-notmobile & {
            &:hover {
              color: $color-red-dark-hover;

              svg {
                left: 1px;

                path {
                  fill: $color-red-dark-hover;

                }
              }
            }
          }
        }
      }
    }
  }
  .carousel {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 40px;
    button {
      @media only screen and (max-width: $breakpoint-small) {
        display: none;
      }
    }
    .images {
      width: calc(100% - 72px);
      @media only screen and (max-width: $breakpoint-small) {
        width: 100%;
      }
    }
    .swiper {
      width: calc(100% - 40px);
      margin-left: 20px;
      @media only screen and (max-width: $breakpoint-small) {
        margin-left: 0;
        width: 100%;
      }
      .swiper-slide {
        //height: auto;
        @media only screen and (max-width: $breakpoint-small) {
          margin-bottom: 10px;
        }
        .inset {
          padding:15px 10px;
          @media only screen and (max-width: $breakpoint-small) {
            padding:0;

          }
        }
        .bgimg {
          border-radius: 10px;
          position: relative;
          aspect-ratio: 1;
          display: block;
          .soldout {
            position: absolute;
            left:0;
            bottom:0;
            width: 100%;
            background-color: rgba(0,0,0,0.8);
            display: flex;
            align-items: center;
            justify-content: center;
            span {
              padding:10px 0;
              @include font-rubik-medium();
              font-size: 16px;
              color: #fff;
            }
          }
        }
        h3 {
          color: #fff;
          @include font-rubik-semibold();
          text-transform: uppercase;
          font-size: 18px;
          margin-top: 20px;
        }
      }

    }
  }
}
