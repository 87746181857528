/////////////////////////////////////////////////////////
//GLOBAL TAGS
/////////////////////////////////////////////////////////
html, body {
  background-color: $color-light;
  scroll-behavior: smooth;
}

body {
  @include vh100();
  &.modal-open {
    overflow: hidden;
    position: fixed;
    width: 100%;
  }
}

#container {
  width: 100%;
}

#content {
  //@include vh100();
  //@include master-padding-tb();
}

#meta-sitewidth {
  display: none;
  z-index: 0;
  padding-top: $max-width-site;
}
#meta-breakpoint {
  display: none;
  z-index: 0;
  padding-top: $breakpoint-small;
}

a {
  text-decoration: none;
}

.generic-holder {
  @include master-padding-tb();
  min-height: 300px;

  .generic-holder-content {
    padding-top: 40px;
  }
}

.blocks-holder {
  width: 100%;
}
.block {
  width:100%;
  //@include blocks-margin-tb();
}


.generic-lr {
  display: flex;
  justify-content: space-between;
  .l {
    width: 100%;
    margin-right: 100px;
  }
  .r {
    @include widthMaxMin(375px);
  }
  @media only screen and (max-width: $breakpoint-small) {
    flex-wrap: wrap;
    .l {
      width: 100%;
      margin-right: 0
    }
    .r {
      @include widthMaxMin(100%);
      margin-top: 40px;
    }
  }
}