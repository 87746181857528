.coloredBackgroundBlock {
  background-color: #0659A5;
  @media only screen and (max-width: $breakpoint-small) {
    //height: 80vh;
  }
  &.fan-club {
    background-color:#65A75F;
  }
  .graphic {
    @media only screen and (max-width: $breakpoint-small) {
      width: 100%;
      height: 100%;
    }
    svg {
      width: 100%;
      @media only screen and (max-width: $breakpoint-small) {

      }
    }
  }
  .copy {
    .capper, .capper-padded, .flx {
      height: 100%;
    }
    .flx {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      .content {
        text-align: center;
        max-width: 900px;
        z-index: 100;
        h2 {
          @include font-rubik-extrabold();
          font-size: 42px;
          line-height: 1em;

          color: $color-yellow;
          margin: 10px 0 0 0;
          text-transform: uppercase;
          @media only screen and (max-width: $breakpoint-small) {
            font-size: 32px;
          }
        }
        h3 {
          @include font-rubik-black();
          font-size: 18px;
          color: #fff;
          margin: 0;
          text-transform: uppercase;
          letter-spacing: 0.1em;

        }
        p {
          @include font-roboto-regular();
          font-size: 16px;
          color: #fff;
          max-width: 600px;
          margin:15px auto;
        }
        a {

          @include font-roboto-bold();
          @include transition-all();
          color: #CCFF00;
          &:hover {
            color: rgba(204, 255, 0, 0.7);
          }
        }
        .btn {
          margin-top: 20px;
        }
      }
    }
  }
}