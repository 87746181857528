// SIZES
$headerTall:175px;
$headerSmall:120px;

//COLORS
$color-light: #ffffff;
$color-dark: #1C1C1C;

$color-gray: #9E9E9E;

$color-yellow: #F8DD2E;
$color-red: #DA1B55;
$color-red-dark-hover: #9A002F;

$color-hover: $color-red;
$color-error: $color-red;
