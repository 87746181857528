///////////////////////////////////////////////////////////////////////////
// CUSTOM TO PROJECT

///////////////////////////////////////////////////////////////////////////
// GLOBAL-ISH
[data-aos="custom-fade-in"] {
  transition-property: opacity;
  opacity: 0;
  &.aos-animate {
    opacity: 1;
  }
}

[data-aos="custom-svg"] {
  .anihook {
    @include willChange();
    transition: opacity 500ms ease-in-out;
    opacity: 0;
    &.clockwise {
      transform: rotate(5deg);
    }
    &.counter {
      transform: rotate(-5deg);
    }
    &.delay1 {transition-delay: 100ms;}
    &.delay2 {transition-delay: 200ms;}
    &.delay3 {transition-delay: 300ms;}
    &.delay4 {transition-delay: 400ms;}
    &.delay5 {transition-delay: 500ms;}
    &.delay6 {transition-delay: 600ms;}
    &.delay7 {transition-delay: 700ms;}
    &.delay8 {transition-delay: 800ms;}
  }

  &.aos-animate {
    .anihook {
      opacity: 1;
      //transform: rotate(0deg);
    }
  }
}



[data-aos="custom-img-slide-reveal--from-left"] {
  img {
    transition-property: transform;
    transition-timing-function:cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-duration:800ms;
    transform: translateX(-100%);
    body #app.lang-ar & {
      transform: translateX(100%);
    }
  }
  &.aos-animate {
    img {
      transform: translateX(0);
      body #app.lang-ar & {
        transform: translateX(0);
      }
    }
  }
}
[data-aos="custom-img-slide-reveal--from-right"] {
  img {
    transition-property: transform;
    transition-timing-function:cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-duration:800ms;
    transform: translateX(100%);
    body #app.lang-ar & {
      transform: translateX(-100%);
    }
  }
  &.aos-animate {
    img {
      transform: translateX(0);
      body #app.lang-ar & {
        transform: translateX(0);
      }
    }
  }
}
[data-aos="custom-img-slide-reveal--from-up"] {
  img {
    transition-property: transform;
    transition-timing-function:cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-duration:800ms;
    transform: translateY(-100%);
  }
  &.aos-animate {
    img {
      transform: translateY(0);
    }
  }
}
[data-aos="custom-img-slide-reveal--from-down"] {
  img {
    transition-property: transform;
    transition-timing-function:cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-duration:800ms;
    transform: translateY(100%);
  }
  &.aos-animate {
    img {
      transform: translateY(0);
    }
  }
}

[data-aos="custom-scale-in"] {
  transition-property: transform;
  transform: scale(0);
  &.aos-animate {
    transform: scale(1);
  }
}

[data-aos="custom-fade-in"] {
  transition-property: opacity;
  opacity: 0;
  &.aos-animate {
    opacity: 1;
  }
}
[data-aos="custom-slide-left-mini"] {
  transition-property: transform;
  transform: translateX(40px);
  opacity: 0;
  &.aos-animate {
    transform: translateX(0);
    opacity: 1;
  }
}

[data-aos="custom-slide-right-mini"] {
  transition-property: transform;
  transform: translateX(-40px);
  opacity: 0;
  &.aos-animate {
    transform: translateX(0);
    opacity: 1;
  }
}
[data-aos="custom-slide-up-mini"] {
  transition-property: transform;
  transform: translateY(40px);
  opacity: 0;
  &.aos-animate {
    transform: translateY(0);
    opacity: 1;
  }
}




[data-aos="custom-fade-up-mobile"] {
  @media only screen and (min-width: $breakpoint-small-plus-1) {
    opacity: 1;
    transition-property: none !important;
    transform: none !important;
    animation: none !important;
    pointer-events:auto;
  }

  @media only screen and (max-width: $breakpoint-small) {
    transition-property: transform, opacity;
    transform: translateY(100px);
    opacity: 0;
    &.aos-animate {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

[data-aos="custom-fade-up-desktop"] {
  @media only screen and (max-width: $breakpoint-small) {
    opacity: 1;
    transition-property: none !important;
    transform: none !important;
    animation: none !important;
    pointer-events:auto;
  }

  @media only screen and (min-width: $breakpoint-small-plus-1) {
    transition-property: transform, opacity;
    transform: translateY(100px);
    opacity: 0;
    &.aos-animate {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
