.promo-strip {
  width: 100%;
  background-color: $color-yellow;
  padding:20px 0;
  text-align: center;
  .rte {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .bell {
    width: 24px;
    display: inline-block;
    margin-right: 10px;
  }
}
header.header {
  background-color: $color-light;
  top:0;
  left:0;
  width:100%;
  position: relative;
  @include transition-all();
  z-index: 10000;

  @media only screen and (max-width: $breakpoint-mobile-nav) {
    display: none;
  }

  .lr {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 20px 0;
    width: 100%;
    .l {
      width: 234px;
      margin-right: 50px;
      img {
        width: auto;
        height: 116px;
      }
    }
    .r {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      .bl {
        height: 41px;
        svg {
          width: 264px!important;
          height: 41px!important;
        }
        img  {
          width: 264px!important;
          height: 41px!important;
        }
      }

      //mobile only stuff
      .lbl-only,
      .caret {
        display: none;
      }

      .l1 {
        display: flex;
        align-items: center;
        .btn {
          margin-left: 17px;
        }
      }
      .l2 {
        width: 100%;
        //max-width: 500px;
        body.site-mode-sale & {
          //max-width: 700px;
        }
        nav {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
      ul.ul-1 {
        width: 100%;
        list-style: none;
        display: flex;
        justify-content: flex-end;

        li.li-1 {
          display: inline;
          margin-left: 5vw;
          padding-bottom: 20px;
          top:20px;
          &:first-child {
            margin-left: 0;
          }
          .txt {
            @include font-rubik-semibold();
            color: $color-dark;
            font-size:16px;
            @include transition-all();
            text-transform: uppercase;
            body.device-notmobile & {
              &:hover {
                color: $color-red;
              }
            }
          }

          &:after {
            content: "";
            position: absolute;
            top:36px;
            left:-10px;
            width: calc(100% + 20px);
            height: 4px;
            background-color: $color-red;
            transform: scaleX(0);
            visibility: hidden;
            @include transition-all();
            z-index: 10;
          }
          &.active,
          &:hover {
            &:after {
              visibility: visible;
              transform: scaleX(1);
            }
          }

          ul.ul-2 {
            @include willChange();
            @include transition-all();
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top:39px;
            left:-50px;
            background-color: #fff;
            z-index: 2;
            padding:20px 20px 10px;
            width: calc(100% + 60px);
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            li.li-2 {
              list-style: none;
              padding-bottom: 20px;
              display: flex;
              align-items: flex-start;
              &.about-add-on {
                display: none;
              }
            }

            &.threecol {
              //display: grid;
              grid-auto-flow: column;
              grid-template-columns: repeat(3, 1fr);
              gap: 20px;
              width: calc(100% + 600px);
              left: -300px;
              columns: 3;
            }
          }
          &:hover {
            ul.ul-2 {
              opacity: 1;
              visibility: visible!important;
            }
          }
        }
      }
    }

  }

}
