.animated-line {
  position: absolute;
  width: 100%;
  overflow: hidden;
  visibility: hidden;
  pointer-events: none!important;
  .al {
    position: absolute;
    transform-origin: 50% 50%;
    @include willChange();
    img {
      width: 100%;
    }
    width: 40vw;
  }
  &.tl {
    top: 0;
    left: 0;
    height: 50vh;
    .al {
      top:-20vw;
      left:-20vw;
      &.edger {
        top:-20vw;
        left:-30vw;
      }
      &.wider {
        top:-20vw;
        left:-20vw;
        width: 50vw;
      }
    }
    @media only screen and (max-width: $breakpoint-small) {
      top: 0;
      left: 0;
      height: 100vh;
      .al {
        top:-40vw;
        left:-60vw;
        width: 100vw;
        &.general-green {
          top:-50vw;
          left:-70vw;
        }
        &.wider {
          top:-40vw;
          left:-50vw;
          width: 100vw;
        }
      }
      &.promos {
        .al {
          &.wider {
            top:-20vw;
            left:-50vw;
            width: 100vw;
          }
        }
      }
    }
  }
  &.tr {
    top: 0;
    right: 0;
    height: 50vh;
    .al {
      top:-20vw;
      right:-20vw;
      &.edger {
        top:-20vw;
        right:-30vw;
      }
      &.wider {
        top:-20vw;
        right:-15vw;
      }
    }
    @media only screen and (max-width: $breakpoint-small) {
      .al {
        width: 100vw;
        top:-60vw;
        right:-60vw;
        &.edger {
          top:-50vw;
          right:-80vw;
        }
      }
    }
  }
  &.bl {
    bottom: 0;
    left: 0;
    height: 50vh;
    .al {
      bottom:-20vw;
      left:-20vw;
      &.edger {
        bottom:-20vw;
        left:-30vw;
      }
      &.wider {
        bottom:-20vw;
        left:-15vw;
      }
    }
    @media only screen and (max-width: $breakpoint-small) {
      height: 100vh;
      .al {
        width:100vw;
        &.wider {
          bottom:-40vw;
          left:-45vw;
        }
      }
    }
  }
  &.br {
    bottom: 0;
    right: 0;
    height: 50vh;
    .al {
      bottom:-20vw;
      right:-20vw;
      &.edger {
        bottom:-20vw;
        right:-30vw;
      }
      &.wider {
        bottom:-20vw;
        right:-15vw;
      }
    }
    @media only screen and (max-width: $breakpoint-small) {
      bottom: 0;
      right: 0;
      height: 100vh;
      .al {
        width: 100vw;
        &.wider {
          bottom:-55vw;
          right:-25vw;
        }
      }
    }
  }
  &.cl {
    top: 0;
    left: 0;
    height: 100%;
    .al {
      top:70vw;
      left:-20vw;
      &.edger {
        top:-20vw;
        left:-30vw;
      }
      &.wider {
        top:70vw;
        left:-10vw;
      }
    }
    &.shows-gray-l {
      top: 0;
      left: 0;
      height: 100%;
      .al {
        top:70vw;
        left:-35vw;
        img {
          width: 150%;
        }
      }
    }
  }
  &.cr {
    bottom: 20%;
    right: 0;
    height: 100vh;
    .al {
      bottom:0;
      right:-20vw;
      &.edger {
        bottom:-20vw;
        right:-30vw;
      }
      &.wider {
        bottom:-20vw;
        right:-15vw;
      }
    }
    @media only screen and (max-width: $breakpoint-small) {
      .al {
        width:100vw;
        &.edger {
          bottom:-40vw;
          right:-75vw;
        }
      }
    }
    &.shows-gray-r {
      bottom: 20%;
      right: 0;
      height: 100%;
      .al {
        bottom: 0;
        right: -10vw;
        img {
          width: 150%;
        }
      }
    }
  }

  &.home-l {
    overflow: unset;
    overflow-y: visible;
    bottom: 0;
    left: 0;
    height: 50vh;
    .al {
      bottom:-20vw;
      left:-30vw;
      width: 50vw;
      @media only screen and (max-width: $breakpoint-small) {
        bottom:-50vw;
        left:-60vw;
        width: 100vw;
      }
    }

  }
  &.home-r {
    overflow: unset;
    overflow-y: visible;
    bottom: 20%;
    right: 0;
    height: 100vh;
    .al {
      bottom:-15vw;
      right:-40vw;
      width: 50vw;
      @media only screen and (max-width: $breakpoint-small) {
        top:30vw;
        right:-85vw;
        width: 100vw;
      }
    }

  }
}