body.page-home {
  .site-content {
    .featured-block, .coloredBackgroundBlock, .block-promos {
      overflow: hidden;
    }
    //overflow-x: hidden;
  }
  .featured-block {
    line-height: 0;
    width: 100%;
    z-index: 2;
    background-color: #1c1c1c;
    @media only screen and (max-width: $breakpoint-small) {
      height: 80vh;
    }
    .graphic {
      width: 100%;
      @media only screen and (max-width: $breakpoint-small) {
        height: 100%;
      }
      video, img {
        width: 100%;
        height: auto;
        @media only screen and (max-width: $breakpoint-small) {
          height: 100%;
          object-fit: cover;
        }
      }

      .fill {
        width: 100%;
        height: 100%;
        background: radial-gradient(circle, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      }
    }
    .copy {
      line-height: 1.15;
      .capper, .capper-padded, .flx {
        height: 100%;
      }
      .flx {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        .content {
          text-align: center;
          h1 {
            @include font-rubik-extrabold();
            font-size: 100px;
            line-height: 1em;
            color: #fff;
            margin: 0;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            @media only screen and (max-width: $breakpoint-small) {
              font-size: 48px;
            }
          }
          h2 {
            @include font-rubik-medium();
            font-size: 42px;
            line-height: 1em;

            color: $color-yellow;
            margin: -10px 0 0 0;
            text-transform: uppercase;
            letter-spacing: 0.4em;
            @media only screen and (max-width: $breakpoint-small) {
              font-size: 22px;
              margin: -5px 0 0 0;
            }
          }
          h3 {
            @include font-rubik-medium();
            font-size: 22px;
            color: #fff;
            margin: 0;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            @media only screen and (max-width: $breakpoint-small) {
              font-size: 14px;
              margin-bottom: 3px;
            }
          }
          p {
            @include font-roboto-regular();
            font-size: 18px;
            color: #fff;
            max-width: 720px;
            margin:15px auto;
            @media only screen and (max-width: $breakpoint-small) {
              font-size: 16px;
            }
            a {
              color: #f8dd2e;
              @include transition-all();
              &:hover {
                color: rgba(248, 221, 46,0.8);
              }
            }

          }
          .btn {
            margin-top: 20px;
          }
        }
      }
    }
  }
}
