.block-directions {
  margin-bottom: 150px;
  .graphic {
    img {
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
  .copy {
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    .copy2 {
      padding:40px;
    }
  }
  .cols {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(2, 1fr);
    @media only screen and (max-width: $breakpoint-small) {
      grid-template-columns: repeat(1, 1fr);
      gap: 50px;
    }
    .col {
      h1, h2 {
        @include font-rubik-black();
        text-transform: uppercase;
        font-size: 32px;
        color: #494949;
        margin-bottom: 10px;
        @media only screen and (max-width: $breakpoint-small) {
          color: #000;
        }
      }
      h3, h4, h5, h6 {
        @include font-rubik-medium();
        font-size: 18px;
        color: #494949;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      p {
        color: #494949;
        @include font-roboto-regular();
        font-size: 16px;
      }
      a {
        color: $color-red;
        @include transition-all();
        @include font-rubik-semibold();
        body.device-notmobile & {
          &:hover {
            color: $color-red-dark-hover;
          }
        }
      }
    } //col
  }//cols
  .stations {
    .item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 10px;
      .circle {
        width:30px;
        height: 30px;
        margin-right: 7px;
        img {
          width: 100%;
        }
      }
      .txt {
        @include font-roboto-bold();
        font-size: 14px;
        color: #494949;
      }
    }
  }
}