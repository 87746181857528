body.page-general {
  background-color: #1C1C1C;
  .featured-block {
    padding:40px 0;
    .graphic {
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .copy {
      padding:40px 0;
      @media only screen and (max-width: $breakpoint-small) {
        padding:20px 0;
      }
      .capper, .capper-padded, .flx {
        height: 100%;
      }
      .flx {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        .content {
          text-align: center;
          h1 {
            @include font-rubik-extrabold();
            font-size: 62px;
            line-height: 1em;
            color: #fff;
            margin: 0;
            text-transform: uppercase;
            letter-spacing: 0;
            @media only screen and (max-width: $breakpoint-small) {
              font-size: 32px;
            }
          }
          h2 {
            @include font-rubik-medium();
            font-size: 32px;
            line-height: 1em;

            color: $color-yellow;
            margin: 0 0 0 0;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            @media only screen and (max-width: $breakpoint-small) {
              font-size: 14px;
            }
          }
          h3 {
            @include font-rubik-medium();
            font-size: 22px;
            color: #fff;
            margin: 0;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            @media only screen and (max-width: $breakpoint-small) {
              font-size: 14px;
              margin-bottom: 5px;
            }
          }
          p {
            @include font-roboto-regular();
            font-size: 18px;
            color: #fff;
            max-width: 720px;
            margin:15px auto;
            @media only screen and (max-width: $breakpoint-small) {
              font-size: 16px;
            }
            a {
              color: #f8dd2e;
              @include transition-all();
              &:hover {
                color: rgba(248, 221, 46,0.8);
              }
            }
          }
          .btn {
            margin-top: 20px;
          }
        }
      }
    }
  }



  .general-wrapper {
    margin-bottom: 100px;
    .general-wrapper2 {
      padding:40px 0;
    }
    .block-open-copy {
      margin:0 auto;
      .inner2 {
        padding: 0;
      }
    }
  }
}
