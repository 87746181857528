$mobilenav_hamburger_off: #000;
$mobilenav_hamburger_on: #000;

$headerHeightSmall: 0;
#header-mobile,
#mobile-nav-overlay-outer,
#mobile-nav-hamburger,
.header-mobile-bar {
  display: none;
}

@media only screen and (max-width: $breakpoint-mobile-nav) {
  // hide desktop
  .header-strip, .header-logo, .header-shows {
    display: none;
  }
  .header-mobile-bar {
    position: -webkit-sticky;
    position: sticky;
    top:0;
    background-color: #fff;

    z-index: 100001;
    display: block;
    padding:10px 0;

    .lr {
      display: flex;
      width: 100%;

      .l {
        @include widthMaxMin(150px);
        img {
          width: 100%;
        }
      }

      .r {
        width: 100%;
      }
    }
  }
  #mobile-nav-overlay-outer {
    background-color: #fff;
    display: block;
    //position: fixed;
    //top: $headerHeightSmall;
    //left:0;
    width: 100%;
    z-index: 1000;
    overflow: hidden;
    padding-bottom: 10px;
    margin-bottom: 10px;

    &.hidden {
      border-bottom: none;
      display: none;
    }
  }
  #header-mobile {
    display: block;
  }
  .header-mobile {
    display: block;
    position: absolute;
    width: 70px;
    height: 50px;
    z-index: 5000;
    top: 12px;
    right: 5px;

  }
  #mobile-nav-inner {
    padding: 0;
  }
  #mobile-nav-hamburger {
    display: block;
    position: absolute;
    top: 23px;
    right: -2px;
    z-index: 2;
  }
  .hamburger {
    padding: 0;
  }
  .hamburger-inner,
  .hamburger-inner::after,
  .hamburger-inner::before {
    background-color: $mobilenav_hamburger_off;
    border-radius: 0;
  }
  .is-active .hamburger-inner,
  .is-active .hamburger-inner::after,
  .is-active .hamburger-inner::before {
    background-color: $mobilenav_hamburger_on !important;
  }

  #mobile-nav-overlay {
    //background-color: #000;
    //border-top: 4px solid $color-blue-dk;
    //border-bottom: 4px solid $color-blue-dk;
    //@include master-padding-lr-width();
    position: relative;
    @include transition_all();
    display: block;

    &.hidden {
      display: none;
    }

    nav {
      padding: 20px 0 ;
    }

    @media only screen and (max-width: $breakpoint-mobile-nav) {
      .lang-switcher {
        display: none;
      }
    }
  }
  //mobile-nav-overlay


  .mobile-content {
    width: 100%;
    text-align: left;
    margin: 0 auto;

    .ul-1 {

      .li-1 {
        list-style: none;
        //margin-bottom: 15px;
        padding:15px 0;

        &:last-child {
          margin-bottom: 0;
        }

        border-bottom: 1px solid #000;

        .txt {
          @include master-padding-lr-width();
          @include font-rubik-semibold();
          font-size: 16px;
          @include transition_all();
          text-transform: uppercase;
          color: #000;
          @media only screen and (max-width: $breakpoint-tiny) {
            font-size: 14px;
          }

          &.active {
            //color: $color-red;
          }

          &:hover {
            color: $color-red;
          }
        }


        &.has-children {
          cursor: pointer;
          .txt.link-only {
            display: none;
          }
          .txt.lbl-only {
            @include transition-all();
          }
          .caret {
            position: absolute;
            top:12px;
            right:20px;
            .triangle {
              display: block;
              @include transition-all();
              transform: rotate(0deg);
            }
            svg {
              width: 10px;
              height: auto;
              path {
                @include transition-all();
              }
            }
          }
          &:hover {
            .lbl-only {
              color: $color-red;
            }

            .caret {
              svg {
                path {
                  fill: $color-red;
                }
              }
            }
          }
        }//has children
      }

      //li
    }
    .ul-2 {
      display: none;
      padding:20px 0 0;
      .li-2 {
        list-style: none;
        margin-bottom: 10px;
        display: flex;
        &:last-child {
          margin-bottom: 0;
        }
        a {
          @include font-rubik-semibold();
          font-size: 14px;
          @include transition_all();
          text-transform: uppercase;
          color: #000;

          &.active {
            //color: $color-red;
          }

          &:hover {
            color: $color-red;
          }
        }
      }
    }


    .li-1.open {
      background-color: #F5F5F5;
      .ul-2 {
        display: block;
      }
      .caret {
        .triangle {
          transform: rotate(-90deg) !important;
        }
      }
    }


    .bl {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      padding: 20px 0;

      svg {
        width: 264px!important;
        height: 41px!important;


      }
      img  {
        width: 264px!important;
        height: 41px!important;
      }
    }

    .btn {
      margin: 20px auto;
      display: block;
    }
    .view-all-shows-button {
      .btn {
        margin: 10px auto;
      }
    }

  } //mobile-content


}
