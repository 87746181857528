.block-sponsors {
  .footer-sponsors {
    background-color: $color-light;
    //@include master-padding-tb();

    .inner {
      width: 100%;
      //margin: 40px 0 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .intro {
      display: flex;
      align-items: center;
      width: 100%;
      @include font-rubik-medium();
      font-size: 24px;
      color: #000;
      text-transform: uppercase;
      @include widthMaxMin(250px);
    }

    .panels {
      display: flex;
      //
      width: 100%;

      &.larger-quantity {
        width: calc(100% - 502px); //250, 100, 100, 36, 36
      }
    }

    .swiper-wrapper {
      width: 100%;
    }

    .minicap.smaller-quantity {
      aspect-ratio: 1;

      display: flex;
      align-items: center;
      justify-content: center;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
      }


      img {
        width: 100%;
        max-width: 200px;
      }
    }

    .line, .line-empty {
      text-align: center;
      @include widthMaxMin(100px);

      span {
        border-radius: 50%;
        width: 1px;
        height: 77px;
        display: inline-block;
        background-color: #000;
      }
    }

    .logo-line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      width: 100%;
    }

    .minicap {
      width: 100%;
      max-width: 300px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
    }

    a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }

    .lbl {
      @include font-roboto-regular();
      font-size: 12px;
      color: #000;
      margin-bottom: 10px;
    }

    img {
      width: 100%;
      max-width: 200px;
    }

    .bgimg {
      background-size: contain;
      width: 96%;
      height: 96%;
      left: 3%;
      top: 3%;
    }

    .swiper-wrapper {
      .swiper-slide {
        aspect-ratio: 1;

        &.swiper-slide-next {
          .line {
            span {
              display: none !important;
            }
          }
        }

      }

      //panel
    } //panels


    @media only screen and (max-width: $breakpoint-small) {
      margin: 0 auto;
      max-width: 360px;
      width: 60%;
      min-width: 260px;
      .intro {
        width: 100%;
        @include widthMaxMin(100%);
        text-align: center;
        display: block;
        margin: 0 auto;
      }
      .inner {
        flex-wrap: wrap;
        margin:40px 0 100px;
      }
      .lbl {
        text-align: center;
      }
      .line, .line-empty  {
        display: none;
      }
      .btn-arrow-left, .btn-arrow-right {
        position: absolute;
        bottom: -50px;
      }
      .btn-arrow-left {
        left:0;
      }
      .btn-arrow-right {
        right:0;
      }
      .panels.larger-quantity {
        width: 100%;
      }
      .swiper-wrapper {
        width: 100% !important;
        //height: auto;
        .swiper-slide {
          //aspect-ratio: 2;

          //height: auto!important;
          //height: 150px;
          aspect-ratio: unset;
          .logo-line {
            border-bottom: 1px solid #000;
            margin-bottom: 20px;
            //padding-bottom: 20px;
          }
        }
      }
      img {
        max-width: unset;
      }
      .intro {
        &.smaller-quantity {
          border-bottom: 1px solid #000;
          padding-bottom: 20px;
          margin-bottom: 20px;
        }
      }
      .minicap {
        max-width: unset;
        &.smaller-quantity {
          aspect-ratio: unset !important;
          text-align: center;
          .wrap {
            border-bottom: 1px solid #000;
            margin:0 0 20px 0;
            padding:0 0 20px 0;
          }
          img {
            max-width: unset;
          }
        }
      }
      .wrap {
        width: 100%;
      }
    }

    &.smaller-quantity {
      .inner {
        margin-bottom: 40px;
      }
    }

  }//.footer-sponsors {
}
