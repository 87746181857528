body.page-shows {
  .main-zone {
    background-color: #1C1C1C;
    .top {
      text-align: center;
      padding:80px 0 40px;
      margin: 0 auto 25px;
      max-width: 650px;
      h3 {
        @include font-rubik-medium();
        font-size: 22px;
        letter-spacing: 0.1em;
        color: $color-yellow;
        margin:0;
        text-transform: uppercase;
        @media only screen and (max-width: $breakpoint-small) {
          font-size: 14px;
        }
      }
      h1 {
        @include font-rubik-extrabold();
        font-size: 52px;
        letter-spacing: 0;
        color: #fff;
        margin:0;
        text-transform: uppercase;
        @media only screen and (max-width: $breakpoint-small) {
          font-size: 32px;
        }
      }
      h2 {
        @include font-rubik-medium();
        font-size: 22px;
        letter-spacing: 0.1em;
        color: #fff;
        margin:0;
        text-transform: uppercase;
        @media only screen and (max-width: $breakpoint-small) {
          font-size: 14px;
        }
        margin-bottom: 10px;
      }
      p {
        @include font-roboto-regular();
        font-size: 18px;
        letter-spacing: 0;
        color: #fff;
        margin:0;
        @media only screen and (max-width: $breakpoint-small) {
          font-size: 16px;
        }
      }
    }
    .grid {
      padding-bottom: 100px;
      display: grid;
      row-gap: 100px;
      column-gap: 40px;
      grid-template-columns: repeat(4, 1fr);
      @media (max-width: $breakpoint-medium) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: $breakpoint-small) {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 30px;
        column-gap: 30px;
      }
      .poster {
        width: 100%;
        height: auto;
        aspect-ratio: 1;
        border-radius: 10px;
        display: block;
        background-size: cover;
        background-repeat: no-repeat;

        .soldout {
          position: absolute;
          left:0;
          bottom:0;
          width: 100%;
          background-color: rgba(0,0,0,0.8);
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            padding:10px 0;
            @include font-rubik-medium();
            font-size: 16px;
            color: #fff;
          }
        }
      }
      h3 {
        color: #fff;
        @include font-rubik-semibold();
        font-size:18px;
        text-transform: uppercase;
        margin-top: 10px;
      }
    }
  }

}
