.block-photostrip {
  background-color: #1C1C1C;
  padding:50px 0 100px;
  overflow: hidden;

  .mobile-bottom {
    margin-top: 20px;
    .btn-arrow-left, .btn-arrow-right {
      margin:0 15px;
    }
  }
  h3 {
    @include font-roboto-regular();
    font-size: 24px;
    color: #fff;
    text-transform: uppercase;
  }
  .carousel {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 40px;

    .btn-arrow-right, .btn-arrow-left {
      @media only screen and (max-width: $breakpoint-small) {
        //top: -13px;
        display: none;
      }
    }
    .images {
      width: calc(100% - 72px);
      @media only screen and (max-width: $breakpoint-small) {
        width: 100%;
      }
    }
    .swiper {
      width: calc(100% - 40px);
      margin-left: 20px;
      .swiper-wrapper {
        @media only screen and (max-width: $breakpoint-small) {
          flex-direction: unset!important;
        }
      }
      .swiper-slide {
        height: auto;
        aspect-ratio: 1.4;

        .bgimg {

        }
      }

    }
  }
}
