.link-underline-grow {
  display: inline-block;
  text-decoration: none;
  &:after {
    content:"";
    display: block;
    width:100%;
    height:2px;
    background-color: #000;
    @include transition_all();
    transform-origin: top center;
  }
  body.device-notmobile & {
    &:hover {
      &:after {
        transform: scaleY(2);
      }
    }
  }
}
.link-underline-leftright {
  cursor:pointer;
  $transTime:250ms;
  $transEaseInOut:all $transTime ease-in-out;
  $transEaseIn:all $transTime ease-in;
  $transEaseOut:all $transTime ease-out;
  $transOff:all 0ms ease-in-out;
  color:#fff;
  transition: $transEaseInOut;
  display: inline-block;
  text-decoration: none;
  overflow: hidden;
  padding-bottom: 3px;
  &:before,
  &:after {
    content:"";
    position: absolute;
    @include calc(top,'100% - 4px');
    @include calc(left,'-100%');
    display: block;
    width:100%;
    height:1px;
    background-color: #fff;
    transform-origin: top center;
  }
  &:before {
    transition: $transOff;
  }
  &:after {
    @include calc(left,'100%');
    transition: $transEaseOut;
  }
  &.active {
    //color:$color-cyan;
    &:before {
      @include calc(left,'-100% + 20px');
    }
    &:after {
      @include calc(left,'-100% + 20px');
    }
  }
  body.device-notmobile & {
    &:hover {
      //color:$color-cyan;
      &:before {
        left: 0;
        transition: $transEaseIn;
      }

      &:after {
        transition: $transEaseOut;
        transition-delay: $transTime;
        left: 0;
      }
    }
  }
  &.small {
    &:before,
    &:after {
      height:1px;
    }
  }
}
.link-underline-leftright-dark {
  @extend .link-underline-leftright;
  &:before,
  &:after {
    background-color:  $color-hover;
  }
}
.link-underline-leftright-red {
  @extend .link-underline-leftright;
  &:before,
  &:after {
    background-color:  $color-hover;
  }
}
.link-underline-leftright-white {
  @extend .link-underline-leftright;
  &:before,
  &:after {
    background-color: #fff;
  }
}

//elipse
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link-underline-grow {
  color: $color-hover;
  @include font-global-bold();
  font-size:18px;
  line-height: 22px;
  text-transform: uppercase;
  @media only screen and (max-width: $breakpoint-mobile-nav) {
    font-size:15px;
    line-height: 15px;
  }
}

.generic-image-hover {
  cursor: pointer;
  opacity:1;
  @include transition-opacity();
  body.device-notmobile & {
    &:hover {
      opacity: 0.85;
    }
  }
}
.scrim {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background: rgb(26,98,137);
  background: linear-gradient(180deg, rgba(26,98,137,1) 0%, rgba(7,64,96,1) 68%, rgba(0,55,85,1) 100%);
}


.mini-nav-action {
  .mini-nav-action2 {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    * {
      @include font-global-bold();
      font-size: 16px;
      color:#fff;
    }
  }
  a {
    svg {
      margin-left: 5px;
      width:12px;
      path {
        fill: #fff;
      }
      @include transition-all();
    }
    body.device-notmobile & {
      &:hover {
        svg {
          transform: translateX(5px);
        }
      }
    }
  }
  &.back {
    a {
      svg {
        margin-right: 5px;
        margin-left: 0;
      }
      body.device-notmobile & {
        &:hover {
          svg {
            transform: translateX(-5px);
          }
        }
      }
    }
  }
}

ul.sidenav-links {
  list-style: none;
  overflow: hidden;
  width:340px;
  li {
    display: flex;
    @include font-global-bold();
    font-size: 20px;
    @include transition-all();
    transform: translateX(-30px);
    margin:20px 0;
    a {
      @include transition-all();
      text-transform: uppercase;
      color: #fff;
      opacity: 0.5;
    }
    svg {
      width:14px;
      @include transition-all();
      opacity: 0.5;
      margin-right: 18px;
      path {
        fill:#fff;

      }
    }
    &.active,
    &:hover {
      transform: translateX(0);
      a, svg {
        opacity: 1;
      }
    }

  }
}

/*
.global-close {
  width:40px;
  height: 40px;
  display: block;
  cursor: pointer;
  &:before, &:after {
    content:"";
    width:40px;
    height: 5px;
    border-radius: 7px;
    background-color: #fff;
    position: absolute;
    top:16px;
    left:0;
    @include transition-all();
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
  &:hover {
    &:before, &:after {
      transform: rotate(0deg);
      background-color: $color-hover;
    }
  }
  &.smaller {
    width: 18px;
    height: 18px;
    &:before, &:after {
      top:8px;
      width:18px;
      height: 2px;
      border-radius: 3px;
      background-color: #000;
    }
  }
}
 */
.global-close {
  width:40px;
  height: 40px;
  display: block;
  cursor: pointer;
  @include transition-all();
  svg {
    width: 100%;
    height: 100%;
    path, circle {
      @include transition-all();
    }
  }
  body.device-notmobile & {
    &:hover {
      transform: scale(1.2);

      svg {
        path, circle {
          stroke: #fff;
        }
      }
    }
  }
  &.smaller {
    width: 25px;
    height: 25px;
  }
}

.below-header {
  padding-top: $headerTall;
  @media only screen and (max-width: $breakpoint-mobile-nav) {
    //padding-top: $headerSmall;
    padding-top: 0;
  }
}




.social-icons {
  display: inline-flex;
  align-items: center;
  height: 100%;
  .icon {
    margin:0 8px;
    &:first-child {
      margin-left: 0;
    }
    svg {
      height:18px;
      width: 18px;
      position: relative;
      top:2px;
      path {
        fill:#000;
        @include transition-all();
      }
    }
    body.device-notmobile & {
      &:hover {
        svg {
          path {
            fill: $color-hover;
          }
        }
      }
    }
  }
}


input {
  border:1px solid #B5B5B5;
  padding:5px 10px;
  @include set-input-placeholder-color(rgba(121,121,121,0.8));
  color: $color-gray;
}

.btn {
  border: none;
  background-color: $color-red;
  @include font-rubik-extrabold();
  font-size: 14px;
  letter-spacing: 0.1em;
  line-height: 1em;
  color:#fff;
  text-transform: uppercase;
  padding:20px 25px 17px; //fat padding
  border-radius:30px;
  &.tighter {
    border-radius:20px;
    padding:11px 20px 9px;
  }

  cursor: pointer;
  @include transition-all();
  body.device-notmobile & {
    &:hover {
      background-color: $color-red-dark-hover;
    }
  }
  &.square {
    border-radius: 0;
    padding-top:15px;
    padding-bottom:15px;
    text-transform: unset;
  }
}


.swiper-button-next, .swiper-button-prev {
  width:52px;
  height: 52px;
  border-radius: 50%;
  background-color: rgba(0,0,0,0.5);
  border:1px solid rgba(255,255,255,0.5);
  @include transition-all();
  @media only screen and (max-width: $breakpoint-small) {
    width:25px;
    height: 25px;
  }
  svg {
    width:15px;
    height: auto;
    @include transition-all();
    path {
      //fill:#fff;
      fill: rgba(255,255,255,.5);
      @include transition-all();
    }
    @media only screen and (max-width: $breakpoint-small) {
      width: 8px;
    }
  }
  &:after {
    display: none;
  }
  body.device-notmobile & {
    &:hover {
      //width:62px;
      //height: 62px;
      //transform: translateX(-5px) translateY(-5px);
      transform: scale(1.2);
      background-color: rgba(0, 0, 0, 1);
      border: 1px solid rgba(255, 255, 255, 1);

      svg {
        path {
          //fill:#fff;
          fill: rgba(255, 255, 255, 1);
        }
      }
    }
  }

}
.swiper-button-next {
  right:-65px;
  @media only screen and (max-width: $breakpoint-small) {
    //right:-85px;
  }
  body.device-notmobile & {
    &:hover {
      border: 1px solid #fff;

      svg {
        transform: scale(0.85);
        //transform: translateX(2px) scaleX(1.1);
      }
    }
  }
}
.swiper-button-prev {
  left:-65px;
  @media only screen and (max-width: $breakpoint-small) {
    //left:-85px;
  }
  body.device-notmobile & {
    &:hover {
      border: 1px solid #fff;

      svg {
        transform: scale(0.85);
        //transform: translateX(-2px) scaleX(1.1);
      }
    }
  }
}

.icon-copy {
  display: flex;
  margin: 5px 0;

  .icon {
    @include widthMaxMin(12px);
    margin-right: 15px;
    svg {
      width: 100%;
      path {
        //fill$color-gray;
        fill:$color-dark;
      }
    }
  }
  .copy {
    * {
      //color$color-gray;
      fill:$color-dark;
      font-size: 16px;
      line-height: 1.2em;
    }
    .bold {
      @include font-global-bold();
    }
    .regular {
      @include font-global-bold();
    }
  }
  &.dark {
    .icon {
      svg {
        path {
          fill:$color-dark;
        }
      }
    }
    .copy {
      * {
        color: $color-dark;
      }
    }
  }
  &.all-gray {
    * {
      font-size: 14px;
    }
    .icon {
      svg {
        path {
          fill:$color-gray!important;
        }
      }
    }
    .copy {
      * {
        color: $color-gray!important;
      }
    }
  }
}

.expandables {
  .row {
    .top {
      display: flex;
      align-items: center;
      cursor: pointer;
      .rowl {
        width:100%;
        * {
          @include font-global-bold();
        }
      }
      .rowr {
        @include widthMaxMin(16px);
        margin-left: 10px;
        top:1px;
        position: relative;
        svg {
          width:100%;
          @include transition-all();
          transform: rotate(-90deg);
        }
      }
    }
    .bottom {
      height: 0;
      opacity: 0;
      overflow: hidden;
      @include transition-all();
      max-height: 100000px;
    }
    .line {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    &.open {
      .top {
        .rowr {
          svg {
            transform: rotate(0deg);
          }
        }
      }
      .bottom {
        opacity: 1;
        .bottom2 {
        }
      }
    }
    &:last-child {
      .line {
        display: none;
      }
    }
  }//row
}

.tour-linkout-row {
  border-top: 1px solid #E0E0E0;
  padding-bottom: 5px;
  padding-top: 5px;
  margin-bottom: 0px;
  display: flex;
  .col {
    padding:10px 10px 10px 0;
    .l1 {
      @include font-global-bold();
    }
  }
  .col1 {
    width:45%;
  }
  .col2 {
    width:35%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .col3 {
    width:20%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  @media only screen and (max-width: $breakpoint-small) {
    display: block;
    .col1, .col2, .col3 {
      width: 100%;
    }
    .col1 {
      padding-bottom: 0;
    }
    .col2 {
      padding-top: 5px;
    }
  }
  &.first {
    border-top: 1px solid transparent;
  }
  &.new-group {

  }
  &.existing-group {
    /*
    border-top: 1px solid transparent;
    .col1 {
      visibility: hidden;
    }

     */
  }
}
.tour-linkout-row-group {
  .tour-linkout-row {
    display: none;
    &:last-child {
      display: flex;
    }
  }
}

.border-hover {
  @include transition-all();
  border-radius: 6px;
  transform: scale(0.95);
  aspect-ratio: 1;
  .bgimg {
    opacity: 0.3;
    border-radius: 6px!important;
    @include transition-all();
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    left:6px;
    top:6px;
  }
  @include transition-all();
  //filter: drop-shadow(0px 0px 4px rgba(0,0,0,0));
  body.device-notmobile & {
    &:hover {
      transform: scale(1);

      .bgimg {
        opacity: 1;
      }

      background-color: rgba(39, 39, 39, 1);
      //filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.45));
    }
  }
}
.border-hover-active {
  transform: scale(1);
  background-color: rgba(39, 39, 39, 1);
  //filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.45));
  .bgimg {
    opacity: 1;
  }
}

.filter-holder {
  .show-mobile.block {
    margin: 20px 0;
    .combobox {

    }
  }
}

.jump {
  cursor: pointer;
  @include transition-all();
  color: #666;
  @include font-global-bold();
  body.device-notmobile & {
    &:hover {
      color: $color-hover;
    }
  }
}

.also-in-hover {
  @include transition-all();
  * {
    @include transition-all();
  }
  svg {
    path {
      @include transition-all();
    }
  }
  body.device-notmobile & {
    &:hover {
      color: #fff !important;

      * {
        color: #fff !important;
      }

      svg {
        path {
          fill: #fff !important;
        }
      }
    }
  }
}


.website-social-bar {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  .bar {
    border-left: 1px solid #B5B5B5;
    margin-left: 10px;
    padding-left: 10px;
    &:first-child {
      border-left: none;
      margin-left: 0;
      padding-left: 0;
    }

    .social-icons {
      .icon {
        a {
          svg {
            path {
              fill: #000;
            }
          }
          body.device-notmobile & {
            &:hover {
              svg {
                path {
                  fill: $color-hover;
                }
              }
            }
          }
        }

      }
    }
  }
}

.simple-underline-on-hover {
  @include transition-all();
  border-bottom:1px solid transparent;

  body.device-notmobile & {
    &:hover {
      //text-decoration: underline;
      border-bottom: 1px solid $color-hover;
    }
  }
  &.dark {
    body.device-notmobile & {
      &:hover {
        //text-decoration: underline;
        border-bottom: 1px solid $color-dark;
      }
    }
  }

}


.content-block {
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
  .bgimg-outer {

  }
  .quick-one-swipe {
    aspect-ratio: 1.9;
    height: auto;
    .swiper-slide {
      .caption {
        display: none;
      }
    }
  }
  #quick-one-captions {
    margin-top: 5px;
    display: block;
    font-size: 12px;
    color:#000;
    * {
      font-size: 12px;
    }
  }
}


.btn-arrow-right, .btn-arrow-left {
  @include widthMaxMin(36px);
  @include heightMaxMin(36px);
  background-color: transparent;
  border: none;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
    circle, path {
      stroke: #808080;
    }
  }
  @include transition-all();
  body.device-notmobile & {
    &:hover {
      transform: scale(1.1);
    }
  }
  &.swiper-button-disabled {
    opacity: 0.2;
    cursor: default;
    body.device-notmobile & {
      &:hover {
        transform: scale(1);
      }
    }
  }
}
.btn-arrow-right {
  //transform: rotate(180deg);
  body.device-notmobile & {
    &:hover {
      transform: scale(1.1);
      //transform: scale(1.1) rotate(180deg);
    }
  }
}


.imgover {
  cursor: pointer;
  @include transition-opacity();
  body.device-notmobile & {
    &:hover {
      opacity: 0.85;
    }
  }
}

.iconover {
  cursor: pointer;
  border: 0; // No physical border
  box-shadow: inset 0 0 0 5px transparent; // Create an "inner border" effect
  @include transition-all();

  body.device-notmobile & {
    &:hover {
      box-shadow: inset 0 0 0 5px #3B3B3B; // Inner border appears on hover
      transform: scale(1.05);
    }
  }
}



.mobile-bottom {
  display: none;
  @media only screen and (max-width: $breakpoint-small) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    .btn-arrow-left, .btn-arrow-right {
      svg {
        circle, path {
          stroke: #808080;
        }
      }
    }
    .chip {
      background-color: #000;
      border-radius: 10px;
      padding:4px 10px;
      margin: 0 30px;
      top: -3px;
      .txt {
        letter-spacing: 0.1em;
        @include font-rubik-medium();
        text-transform: uppercase;
        font-size: 14px;
        color: #fff;
      }
    }
  }
}