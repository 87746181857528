.block-callouts {
  background-color: $color-yellow;
  padding:50px 0 100px;
}
.show-callouts {
  padding:20px 0;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  @media only screen and (max-width: $breakpoint-small) {
    padding:15px 0;
  }
  .callout {
    border-radius: 10px;
    width: calc(50% - 10px);
    margin:20px 0;
    overflow: hidden;
    @media only screen and (max-width: $breakpoint-small) {
      width: 100%;
    }
    .graphic {
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &.callout-regular {
      .inner {
        padding: 80px 40px!important;
      }
      &.from-show {
        .inner {
          padding: 60px 40px!important;
        }
      }
    }
    &.callout-centered {
      .inner {
        padding: 40px!important;
        @media only screen and (max-width: $breakpoint-smaller) {
          padding: 30px!important;
        }
        @media only screen and (max-width: $breakpoint-tiny ) {
          padding: 20px!important;
        }

      }
    }



    h3 {
      color: #fff;
      font-size: 24px;
      @include font-rubik-extrabold();
      line-height: 1.0em;
      text-transform: uppercase;
    }
    .copy {
      margin-top: 15px;
      p {
        //margin-bottom: 5px!important;

      }
    }


    .centered {
      text-align: center;
      padding:50px 0;
      @media only screen and (max-width: $breakpoint-small) {
        padding:20px 0;
      }
      h3 {
        display: inline-flex;
        align-items: center;
        svg {
          margin-left: 10px;
          top:-3px;
          left:0;
          @include transition-all();
          path {
            @include transition-all();
          }
        }
      }
      body.device-notmobile & {
        a {
          &:hover {
            h3 {
              svg {
                left: 2px;

                path {
                  fill: $color-red-dark-hover;
                }
              }
            }
          }
        }
      }
    }

  }
  &.big {
    .callout {
      h3 {
        font-size: 32px;
        @media only screen and (max-width: $breakpoint-smaller) {
          font-size: 25px;
        }
        @media only screen and (max-width: $breakpoint-tiny) {
          font-size: 20px;
        }
      }
      .copy {

      }
    }
  }
}