@mixin font-rubik-light() {
  font-family: "rubik", sans-serif;
  font-weight: 300;
  font-style: normal;
}
@mixin font-rubik-regular() {
  font-family: "rubik", sans-serif;
  font-weight: 400;
  font-style: normal;
}
@mixin font-rubik-medium() {
  font-family: "rubik", sans-serif;
  font-weight: 500;
  font-style: normal;
}
@mixin font-rubik-semibold() {
  font-family: "rubik", sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin font-rubik-bold() {
  font-family: "rubik", sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin font-rubik-extrabold() {
  font-family: "rubik", sans-serif;
  font-weight: 800;
  font-style: normal;
}

@mixin font-rubik-black() {
  font-family: "rubik", sans-serif;
  font-weight: 900;
  font-style: normal;
}


@mixin font-roboto-regular() {
  font-family: "roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}
@mixin font-roboto-bold() {
  font-family: "roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}


@mixin font-global-regular() {
  font-family: "rubik", sans-serif;
  font-weight: 400;
  font-style: normal;
}
@mixin font-global-bold() {
  font-family: "rubik", sans-serif;
  font-weight: 700;
  font-style: normal;
}