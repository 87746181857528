.lr-common {
  display: flex;
  @media only screen and (max-width: $breakpoint-small) {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }

  .col1 {
    width: 27.7%;
    @media only screen and (max-width: $breakpoint-small) {
      max-width: 210px;
      width: 50%;
      z-index: 2;
      margin:0 auto;
      margin-top: -105px;
      padding-bottom: 15%;
      .code, .btn-outer, .links {
        display: none!important;
      }
    }
    @media only screen and (max-width: 700px) {
      padding-bottom: 20%;
    }
    @media only screen and (max-width: $breakpoint-smaller) {
      padding-bottom: 30%;
    }
    @media only screen and (max-width: 420px) {
      margin-top: -26%;
      padding-bottom: 20%;
    }

  }
  &.without-media {
    .col1 {
      @media only screen and (max-width: $breakpoint-small) {
        margin-top: 0;
      }
    }
    .col1-height-hook .poster-inner {
      align-items: flex-start;
      .img-peekaboo {
       top: 40px;
      }
    }
  }

  .col2 {
    width: 72.3%;
    @media only screen and (max-width: $breakpoint-small) {
      width: 100%;
    }
  }
  &.dsp.with-media {
    .col2 {
      @media only screen and (max-width: $breakpoint-small) {
        padding-bottom:140px;
      }
    }
  }
}

.featured-show-panel {
  background-color: #1C1C1C;

  .capper-padded {
    @media only screen and (max-width: $breakpoint-small) {
      padding: 0;
      width: 100%;
    }
  }

  .featured-show-panel2 {
    padding: 60px 0 120px;
    pointer-events: none;
    @media only screen and (max-width: $breakpoint-small) {
      padding-top: 0;
      padding-bottom: 20px;
    }
  }

  .show-tour-header {
    //border-top-left-radius: 20px;
    //border-bottom-left-radius: 20px;
    border-radius: 20px;
    overflow: hidden;
    pointer-events: none;
    .lr-common {
      pointer-events: none;
    }
  }

  .lr-common {
    .bar {
      .bar-col {
        @media only screen and (min-width: $breakpoint-small-plus-1) {
          min-height: 54px;
          a {
            min-height: 54px;

            button {
              min-height: 54px;
            }
          }
        }
      }
    }



    .col1 {
      @media only screen and (max-width: $breakpoint-small) {
        z-index: 300!important;
      }

    }

    .panel {
      z-index: 200;

      &.img {
        aspect-ratio: 2;
        background-color: #000;
        @media only screen and (max-width: $breakpoint-small) {
          aspect-ratio: 1.54;
        }
        @media only screen and (min-width: $breakpoint-small-plus-1) {
          //border-top-right-radius: 15px;
        }

        .bgimg {
          //background-size: contain;
          @media only screen and (min-width: $breakpoint-small-plus-1) {
            //border-top-right-radius: 15px;
          }
        }
      }

      &.bar {
        display: flex;
        justify-content: space-between;
        @media only screen and (max-width: $breakpoint-small) {
          display: block;
          width: 90%;
          margin: 0 auto;
        }

        .bar-col {
          width: 51%;
          @media only screen and (max-width: $breakpoint-small) {
            width: 100%;
          }

          a, button {
            display: block;
            width: 100%;
            border-radius: 0;
            @media only screen and (max-width: $breakpoint-small) {
              border-radius: 30px;
            }
          }

          button {
            padding: 20px 0;
            @media only screen and (max-width: $breakpoint-small) {
              margin-bottom: 20px;
            }
          }

          &:first-child {
            border-right: 1px solid #000;
            @media only screen and (max-width: $breakpoint-small) {
              border-right: none;
            }
          }

          &:last-child {
            button {
              @media only screen and (min-width: $breakpoint-small-plus-1) {
                border-bottom-right-radius: 15px;
              }
            }
          }
        }

        //bar-col

        &.tours {
          justify-content: right;
          background-color: #000;
          border-bottom-right-radius: 15px;

          .social-icons {
            margin-right: 15px;
          }

          @media only screen and (max-width: $breakpoint-small) {
            margin-bottom: 20px;
            margin-top: -15px;
            text-align: center;
          }
        }
      }

      //bar
    }

    //pael


    //////////////////////////////////////////////////////////////////////////////////////////////////
    &.without-media {
      .poster.img {
        border-radius: 0;
      }

      @media only screen and (max-width: $breakpoint-small) {
        .poster-outer {
          padding-top: 20px;
        }
        .poster.img {
          width: 100%;
          margin: 0 auto;
        }
      }

      .btns {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .btn-outer {
          margin: 20px 20px 20px 0;
        }

        @media only screen and (max-width: $breakpoint-small) {
          display: none;
        }
      }

      .copy {
        @media only screen and (max-width: $breakpoint-small) {
          display: none;
        }

        .rte {
          @include calc(width, '100% - 80px');
        }

        h2 {
          margin-bottom: 5px;
        }

        h6 {
          color: $color-gray;
        }

        .description {
          margin-top: 10px;
        }
      }

      .social-icons {
        display: flex;
        justify-content: center;
        height: auto;
        width: 100%;
        margin: 20px 0 0;

        .icon {
          a {
            svg {
              path {
                fill: #fff;
              }
            }

            body.device-notmobile & {
              &:hover {
                svg {
                  path {
                    fill: $color-red;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.no-media {
    z-index: 100;
    overflow: visible;
    height: 200px;
    padding-top: 50px;
    @media only screen and (max-width: $breakpoint-small) {
      padding-bottom: 50px;
      height: auto;
      min-height: 200px;
    }
    .featured-show-panel2 {
      padding:0!important;
    }
    .show-tour-header {
     background-color: transparent;
    }
    .col1 {
      background-color: #000;
      min-width: calc(27.7% - 40px);
    }
  }

}

.show-tour-header {
  //background-color: #000000;
  background-color: transparent;
  @media only screen and (max-width: $breakpoint-small) {
    background-color: transparent;
  }

  &.without-media {
    .lr {
      align-items: flex-end;
      margin-bottom: 40px;
    }

    @media only screen and (max-width: $breakpoint-small) {
      @include master-padding-lr-width();
    }
  }
}

.special-hook-mobile-tours-without-media {
  width: 100%;
  margin-top: 15px;

  a {
    display: block;
    width: 100%;

    button {
      width: 100%;
    }
  }
}


.details-show-panel {
  @media only screen and (max-width: $breakpoint-small) {
    background-color: #F8DD2E;
  }
  .animated-line {
    @media only screen and (max-width: $breakpoint-small) {
      display: none;
    }
  }
  .bg-yellow, .bg-white {
    position: absolute;
    top:0;
    height: 100%;
    width: 50%;
    @media only screen and (max-width: $breakpoint-small) {
      display: none;
    }
  }
  .bg-yellow {
    background-color: #F8DD2E;
    @media only screen and (max-width: $breakpoint-small) {
      background-color: #fff;
    }
    left:0
  }
  .bg-white {
    background-color: #fff;
    right:0
  }
  .graphic {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .graphic-col {
      width: 33%;
      position: absolute;
      bottom:0;
      left:0;
      svg {
        width: 100%;
        height: auto;

      }
    }


  }

  .lr-common {
    .col1 {

    }

    .col2 {
      background-color: #fff;

      .important-info-mobile {

      }

      .inner {
        padding: 40px;
        @media only screen and (max-width: $breakpoint-small) {
          padding: 30px;
          h1 {
            text-align: center;
          }
        }
        .tighter {
          margin:25px 0;
          p {
            margin-bottom: 5px!important;
          }
        }
      }
    }
  }
}

.page-show {
  .links {
    margin: 10px auto;
    text-align: center;

    &:last-child {
      margin-bottom: 0;
    }

    a, span {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      @include font-rubik-medium();
      font-size: 14px;
      letter-spacing: 0.1em;
      color: $color-red;
      text-transform: uppercase;
      @include transition-all();
      cursor: pointer;

      svg {
        cursor: pointer;
        margin-left: 5px;
        top: -2px;

        path {
          fill: $color-red;
          @include transition-all();
        }
      }

      body.device-notmobile & {
        &:hover {
          color: $color-red-dark-hover;

          svg {
            path {
              fill: $color-red-dark-hover;
            }
          }
        }
      }
    }

  }


}

.col1-height-hook {
  //visibility: hidden;
  background-color: transparent!important;

  .code, .btn-outer, .links {
    visibility: hidden;
  }
  .poster-inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    position: absolute;
    top:0;
    left:0;
    .img-main {
      display: none;
    }
    .img-peekaboo {
      display: block;
      width: calc(100% - 40px);
      left:20px;
    }
  }
  @media only screen and (max-width: $breakpoint-small) {
    visibility: visible;
    height: auto!important;
    background-color: transparent!important;
    .code, .btn-outer, .links {
      visibility: visible;
    }

    .poster-inner {
      position: relative;
      display: block;
      width: 100%;
      height: auto;
      .img-main {
        display: block;
      }
      .img-peekaboo {
        display: none
      }
      img {
        width: 100%;
        border-radius: 20px;
      }
    }
  }
}

.poster-sticky-outer {
  //position: -webkit-sticky;
  position: absolute; // this is updated in JS
  top:0;
  left:0;
  z-index: 1000;
  width: 100%;

  @media only screen and (max-width: $breakpoint-small) {
    display: none;
  }


  .poster-sticky {
    //position: absolute;
    //top:70px;
    left:0;
    top:0;
    padding:20px;
    width: calc(27.7% - 40px);
    background-color: #000;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    @media only screen and (max-width: $breakpoint-small) {
      width: 210px;

    }

    .soldout {
      position: absolute;
      left:0;
      bottom:0;
      width: 100%;
      background-color: rgba(0,0,0,0.8);
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        padding:10px 0;
        @include font-rubik-medium();
        font-size: 16px;
        color: #fff;
      }
    }

    img {
      width: 100%;
      height: auto;
      border-radius: 10px;
      &.img-peekaboo {
        display: none;
      }
      @media only screen and (min-width: $breakpoint-small-plus-1) {
        //border-top-left-radius: 15px;
      }
      @media only screen and (max-width: $breakpoint-small) {
        //width: 56%;
        //margin: -23% auto 40px;
        //z-index: 2;
      }
    }

    .code {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      * {
        color: #fff;
      }

      .line {
        height: 1px;
        max-width: 28px;
        width: 100%;
        background-color: #fff;
        display: block;
      }

      .txts {
        margin: 10px 10px;

        .lbl {
          @include font-rubik-regular();
          font-size: 14px;
          letter-spacing: 0.2em;
        }

        .codetxt {
          @include font-rubik-bold();
          font-size: 18px;
          letter-spacing: 0.2em;
        }
      }
    }

    //code
    .btn-outer {
      margin: 10px 0 15px;

      .btn {
        width: 100%;

        &.sold-out, &.not-on-sale {
          cursor: no-drop;
          background-color: #4A4A4A;
          color: #9E9E9E;
        }

        &.coming-soon {
          cursor: no-drop;

        }
      }
    }

    //button

  }
}


//poster