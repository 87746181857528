.block-promos {
  background-color: $color-yellow;
  .promos {
    padding:150px 0;
    @media only screen and (max-width: $breakpoint-small) {
      padding:40px 0;
    }
    .promo {
      background-color: #1C1C1C;
      border-radius: 10px;
      margin-bottom: 140px;
      @media only screen and (max-width: $breakpoint-small) {
        margin-bottom: 40px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 450px;

        .side-txt {
          width: 50%;
          display: flex;
          align-items: center;
          .side-txt2 {
            padding:40px;
            h3 {
              @include font-rubik-regular();
              font-size: 18px;
              color: $color-yellow;
              text-transform: uppercase;
              letter-spacing: 0.1em;
              margin-bottom: 10px;
            }
            h2 {
              @include font-rubik-extrabold();
              font-size: 32px;
              color: #fff;
              text-transform: uppercase;
              margin-bottom: 10px;
            }
            p {
              @include font-roboto-regular();
              font-size: 16px;
              color: #fff;
              margin-bottom: 15px;
            }
            h4 {
              @include font-rubik-regular();
              font-size: 14px;
              color: $color-yellow;
              text-transform: uppercase;
              margin-bottom: 10px;
            }
            .btn {
              margin-top: 15px;
            }
            a {
              @include font-roboto-regular();
              font-size: 16px;
              color: $color-red;
              @include transition-all();
              &:hover {
                color: $color-red-dark-hover;
              }
            }

          }

        }
        .side-lottie {
          position: absolute;
          right:0;
          top:-85px; // 75 + 10 for the border
          height: calc(100% + 150px);
          aspect-ratio: 1;
          canvas {
            position: absolute;
            width: 100%;
            height: 100%;
            top:0;
            left:0;
          }
          .img {
            position: absolute;
            top:18%;
            left:18%;
            width: 64%;
            height: 64%;
            border: 10px solid #6EC067;
            &.circle {
              border-radius: 50%;
              overflow: hidden;
            }
          }
        }
      } //content
      &.even {
        .content {
          flex-direction: row-reverse;
          .side-lottie {
            right: unset;
            left:0;
          }
        }
      } //event


      // MOBILE
      @media only screen and (max-width: $breakpoint-small) {
        .content {
          flex-direction: column-reverse!important;
          flex-wrap: wrap;
          .side-txt {
            width: 100%;
            text-align: center;

            .side-txt2 {
              padding-top: 0;
            }
          }
          .side-lottie {
            width: 100%;
            right: unset;
            left: 0;
            top:0;
            position: relative;
            margin-top: -10%;
          }
          .btn {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    } //promo
  } //promos

}
