.block-expandables {
  padding-bottom: 100px;
  .block-expandables2 {
    background-color: #fff;
    border-radius: 10px;
    max-width: 830px;
    margin:0 auto 0;
  }
  .expandables {
    margin-bottom: 40px;
    padding:40px;
    @media only screen and (max-width: $breakpoint-small) {
      padding:20px;
    }
    .row {
      border-bottom: 1px solid #C6C1C1;
      padding: 20px 0 20px;
      margin-bottom: 20px;
      @media only screen and (max-width: $breakpoint-small) {
        padding: 15px 0 15px;
        margin-bottom: 15px;
      }
      .top {
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;
        .rowl {
          width:100%;
          h2 {
            @include font-rubik-black();
            font-size: 24px;
            line-height: 1em;
            text-transform: uppercase;
          }
        }
        .rowr {
          @include widthMaxMin(16px);
          margin-left: 10px;
          top:1px;
          position: relative;
          svg {
            width:100%;
            @include transition-all();
            transform: rotate(-90deg);
          }
        }
      }
      .bottom {
        height: 0;
        opacity: 0;
        overflow: hidden;
        @include transition-all();
        max-height: 100000px;
        padding-top: 20px;
        * {
          @include font-roboto-regular();
          font-size: 16px;
        }
        a {
          @include font-roboto-bold();
          color: $color-red;
          @include transition-all();
          body.device-notmobile & {
            &:hover {
              color: $color-red-dark-hover;
            }
          }
        }
        .bottom2 {

        }
      }
      .line {
        margin-top: 20px;
        margin-bottom: 20px;
      }
      &.open {
        .top {
          .rowr {
            svg {
              transform: rotate(0deg);
            }
          }
        }
        .bottom {
          opacity: 1;
          .bottom2 {
          }
        }
      }
      &:last-child {
        .line {
          display: none;
        }
      }
    }//row
  }
}