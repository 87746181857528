footer.footer {
  background-color: #fff;
  .footer2 {
    @include master-padding-tb();
    @media only screen and (max-width: $breakpoint-small) {
      padding-bottom: 80px!important;
    }
  }

  .legal {
    @media only screen and (max-width: $breakpoint-small) {
      margin-bottom: 20px;
    }
    * {
      @include font-roboto-regular();
      font-size: 12px;
    }
    margin-top:10px;
    p {
      margin-top: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .cols {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: $breakpoint-small) {
      display: block;
      text-align: center;
    }
    .col {
      @media only screen and (max-width: $breakpoint-small) {
        margin-right: auto;
        margin-left: auto;
      }
      &.logo {
        max-width: 225px;
      }

      &.nav {
          ul {
            li {
              @include font-roboto-regular();
              font-size: 14px;
              list-style: none;
              margin-bottom: 10px;
              @media only screen and (max-width: $breakpoint-small) {
                margin-bottom: 15px;
              }

              a {
                @include font-roboto-regular();
                font-size: 14px;
                @include transition-all();
                color: #000;
                body.device-notmobile & {
                  &:hover {
                    color: $color-red-dark-hover;
                  }
                }
              }
            }
          }
      }
      &.icons {
        @media only screen and (max-width: $breakpoint-small) {
          margin-top: 10px;
        }
        .icon-lbl {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 20px;
          @media only screen and (max-width: $breakpoint-small) {
            display: inline-block;
            width: 100%;
            margin-top: 10px;
          }
          .lbl {
            @include font-roboto-regular();
            font-size: 14px;
            color: #808080;
            margin-right: 10px;
            @media only screen and (max-width: $breakpoint-small) {
              margin-right: 0;
              margin-bottom: 5px;
            }
          }
        }
      }
    }

    .main-logo {
      img {
        width: 150px;
      }
    }
  }
}
