.block-special-offers {
  background-color: #1C1C1C;
  padding:0 0 100px;

  .filter-by {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    @media only screen and (max-width: $breakpoint-small) {
      justify-content: flex-start;
    }
    .filter-by-label {
      @include font-rubik-medium();
      font-size: 14px;
      color: #fff;
      text-transform: uppercase;
      margin-right: 20px;
      @media only screen and (max-width: $breakpoint-small) {
        min-width: 90px;
        width: 90px;
        margin-right:0;
      }
    }
    .filter-by-select {
      @media only screen and (max-width: $breakpoint-small) {
        width: 100%;
      }
      select {
        border-radius: 20px;
        background-color: #000000;
        padding: 14px 40px 14px 25px;
        border: none;
        width: 100%;
        @include font-rubik-medium();
        font-size: 14px;
        color: #fff;
        text-transform: uppercase;
        cursor: pointer;

        appearance: none; // Removes default browser styling
        -webkit-appearance: none; // For Safari


        background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23FFFFFF' d='M2 5L4 3H0z'/%3E%3C/svg%3E");

        background-repeat: no-repeat;
        background-position: right 15px top 7px;
        background-size: 12px;

        &::placeholder {
          color: #FFF;
        }
        &:focus {
          //outline: 2px solid #fff;
        }
      }

    }

  }



  .grid {
    padding-bottom: 100px;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    @media only screen and (max-width: $breakpoint-small) {
      grid-template-columns: repeat(1, 1fr);
    }
    .item {
      background-color: #fff;
      border-radius: 10px;

      .item2 {
        padding:20px;
        min-height: 200px;
        height: calc(100% - 40px);
        @media only screen and (max-width: $breakpoint-small) {
          height: auto;
          min-height: unset;
        }
        .l1 {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          @media only screen and (max-width: $breakpoint-small) {
            p {
              margin-bottom: 0 !important;
              &.citylink {
                margin-bottom: 5px !important;
              }
            }
          }
        }
        .icon {
          width: 150px;
          height: 150px;
          @media only screen and (max-width: $breakpoint-small) {
            width: 100px;
            height: 100px;
          }
          a { 
            width: 100%;
            height: 100%;
            display: block;
          }
          .bgimg {
            background-size: contain;
          }
        }
        .copy, .copywide {
          width: calc(100% - 170px);
          @media only screen and (max-width: $breakpoint-small) {
            width: calc(100% - 120px);
          }
          h2 {
            @include font-rubik-extrabold();
            font-size: 18px;
            margin-bottom: 5px;
            text-transform: uppercase;
            color:#000;
            @include transition-all();
          }
          a {
            body.device-notmobile & {
              h2 {
                &:hover {
                  color: $color-red-dark-hover;
                }
              }
            }
          }
          p {
            @include font-roboto-regular();
            font-size: 14px;
            margin-bottom: 10px;

          }
          .citylink {
            @include font-rubik-bold();
          }
          a {
            color: $color-red;
            @include transition-all();
            @include font-rubik-bold();
            body.device-notmobile & {
              &:hover {
                color: $color-red-dark-hover
              }
            }
          }
        } //copy copywide
        .copywide {
          width: 100%;
          margin-top: 10px;
        }
      }//item2
      &.span2 {
        grid-column: span 2;
        @media only screen and (max-width: $breakpoint-small) {
          grid-column: span 1;
        }
        .item2 {
          padding: 30px;
          height: calc(100% - 60px);
          @media only screen and (max-width: $breakpoint-small) {
            height: auto;
            padding: 20px;
          }
          .icon {
            width: 210px;
            height: 210px;
            @media only screen and (max-width: $breakpoint-small) {
              width: 100px;
              height: 100px;
            }
          }
          .copy {
            width: calc(100% - 230px);
            @media only screen and (max-width: $breakpoint-small) {
              width: calc(100% - 120px);
            }
          }
        }
      }
    }
  }
}